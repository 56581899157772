/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import parser from 'html-react-parser';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import SlideDown from 'react-slidedown';
import PropTypes from 'prop-types';
import { wrapperClass } from '../utils';
import API from '../services/API';
import Button from './elements/Button';

function AgentClientReview({
    agent, refs, callback, setLoading, showFull, setShowFull,
}) {
    const [itemToShow, setItemToShow] = useState(5);

    const isRequestedBefore = (reviewId) => {
        if (localStorage.getItem(`review-${reviewId}`)) {
            return true;
        }
        return false;
    };

    const sendHelpful = (reviewId, value) => {
        localStorage.setItem(`review-${reviewId}`, value);

        setLoading(true);
        API.sendReview({ reviewId, helpful: value }).then((response) => {
            if (response.responseStatus === 1) {
                toast.success('Your feedback has been sent');
                if (callback) { callback(); }
            } else {
                toast(response.error.message);
            }
            setLoading(false);
        });
    };

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (

        <div
            id="Reviews"
            className={[wrapperClass, 'bg-white'].join(' ')}
        >
            <h2 className="text-center md:text-left tl-cyan uppercase text-2xl md:text-4xl tracking-wide">REVIEWS</h2>
            <div className="flex flex-col gap-4 md:gap-0 md:flex-row p-4 md:py-10">
                <div className="md:pr-10  text-l ">
                    <div
                        className="flex flex-row md:flex-col justify-between gap-1 cursor-pointer min-w-max"
                    >
                        <div className="md:w-full">
                            <StarRatings
                                rating={agent.agentRating.rating}
                                starRatedColor="#0A7790"
                                numberOfStars={5}
                                name="rating"
                                starDimension="30px"
                                starSpacing="1px"
                            />
                            <div>
                                {`Rating: ${agent.agentRating.rating.toFixed(1)}`}
                            </div>
                        </div>
                        <div className="text-sm md:text-base md:w-full text-right md:text-left">
                            <div>
                                {agent.agentRating.totalReviews}
                                {' '}
                                {agent.agentRating.totalReviews > 1 ? 'reviews' : 'review'}
                            </div>
                            <div className="text-right md:text-left">
                                <span className="font-semibold">
                                    {((agent.agentRating.recommended / agent.agentRating.totalReviews) * 100).toFixed(0)}
                                </span>
                                % Recommended
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 text-sm italic">
                        Your trust is our priority.  Every review is submitted by verified travelers after they have booked and completed their trips.
                        <span
                            className="text-red-500 cursor-pointer"
                            onClick={toggleVisibility}
                        >
                            {' '}
                            Learn more about our review guidelines.
                        </span>
                        {isVisible && (
                            <ul className="mt-2 mx-4 list-disc list-outside text-sm text-gray-700">
                                <li>Customer reviews were requested by participating agents.</li>
                                <li>Customers were not compensated or enticed to give positive reviews.</li>
                                <li>All feedback was reviewed and approved by the agency prior to posting.</li>
                            </ul>
                        )}
                    </div>
                    <div className="mt-10 hidden">
                        <div className="text-gray-700 text-xl md:text-2xl">SORT REVIEW BY:</div>
                        <div className="flex flex-col gap-2 mt-5 text-base">
                            <div>Newest</div>
                            <div>Oldest</div>
                            <div>Highest Rating</div>
                            <div>Lowest Rating</div>
                            <div className="flex flex-col gap-2">
                                <div>Keyword</div>

                                <Button
                                    title="SEARCH"
                                    onClick={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4 md:px-10 md:border-l border-gray-500">
                    <SlideDown className="my-dropdown-slidedown">
                        {agent.agentReviews.slice(0, showFull ? agent.agentReviews.length : itemToShow).map((x) => (
                            <div
                                key={x.reviewId}
                                className="flex flex-col gap-2 my-4 p-4"
                                ref={refs[x.reviewId]}
                                style={{ backgroundColor: '#EFF5F5' }}
                            >
                                <div>
                                    <StarRatings
                                        rating={x.rating}
                                        starRatedColor="#0A7790"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="30px"
                                        starSpacing="2px"
                                    />
                                </div>
                                <div className="text-gray-700 text-l font-bold">
                                    {x.reviewTitle}
                                </div>
                                <div className="text-gray-700 text-sm italic">
                                    {`Reviewed By ${x.customerNickname} - ${x.customerLocation} | Traveled to ${x.customerDestination} `}
                                </div>
                                <div className="text-gray-700 text-base my-2">
                                    {parser(x.review)}
                                </div>
                                {x.agentResponse && (
                                    <div className="flex flex-col gap-1 text-sm  self-end mt-auto w-full">
                                        <div className="font-semibold">{`${agent.firstName} ${agent.lastName} responded`}</div>
                                        <div className="p-2 italic quote">
                                            {`"${x.agentResponse}"`}
                                        </div>
                                    </div>
                                )}
                                <div className="font-bold">{x.recommended === 'Y' ? parser('<span class="tl-cyan">✓</span> I would recommend this agent to my family and friends.') : ''}</div>
                                <div className="text-gray-500 text-sm my-2">
                                    {x.helpful}
                                    {' '}
                                    of
                                    {' '}
                                    {x.helpful + x.notHelpful}
                                    {' '}
                                    people found this review helpful. Was this review helpful to you?
                                    <span
                                        className={[isRequestedBefore(x.reviewId) ? 'text-grey-700' : 'tl-red cursor-pointer', ' px-1'].join(' ')}
                                        onClick={() => (!isRequestedBefore(x.reviewId) ? sendHelpful(x.reviewId, true) : {})}
                                    >
                                        YES
                                    </span>
                                    |
                                    <span
                                        className={[isRequestedBefore(x.reviewId) ? 'text-grey-700' : 'tl-red  cursor-pointer',
                                            ' px-1 '].join(' ')}
                                        onClick={() => (!isRequestedBefore(x.reviewId) ? sendHelpful(x.reviewId, false) : {})}
                                    >
                                        NO
                                    </span>
                                </div>
                            </div>
                        ))}
                    </SlideDown>
                    {agent.agentReviews.length > itemToShow && (
                        <div className="text-xs mb-1 cursor-pointer font-semibold mt-2 text-center">
                            {!showFull ? (
                                <Button
                                    title="View more reviews"
                                    onClick={() => setShowFull(!showFull)}
                                    fontSize="uppercase"
                                    width="max-w-sm"
                                />
                            ) : (
                                    <Button
                                        title="View less reviews"
                                        onClick={() => {
                                            setShowFull(!showFull);
                                        }}
                                        fontSize="uppercase"
                                        width="max-w-sm"
                                    />
                                )}
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
}

AgentClientReview.propTypes = {
    agent: PropTypes.object.isRequired,
    refs: PropTypes.array.isRequired,
    setLoading: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    showFull: PropTypes.bool.isRequired,
    setShowFull: PropTypes.func.isRequired,
};

AgentClientReview.defaultProps = {
};

export default AgentClientReview;
