/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import API from '../services/API';

import 'react-datepicker/dist/react-datepicker.css';

function AppointmentForm({ agent }) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState();
    const [availableTimes, setAvailableTimes] = useState([
        {
            title: '',
        },
        {
            title: 'Morning',
            isAvailable: true,
        },
        {
            title: 'Afternoon',
            isAvailable: true,
        },
        {
            title: 'Evening',
            isAvailable: true,
        }]);

    const {
        register, handleSubmit, watch, formState: { errors },
        trigger, setValue,
    } = useForm();
    const onSubmit = (data) => {
        if (isCaptchaVerified) {
            setIsProcessing(true);
            data.comment = videoHtmlTag(moment(selectedDate).format('MMMM Do, YYYY'), data.preferredTime, data.comment);
            API.sendEmailToAgent(data).then((response) => {
                if (response.responseStatus) {
                    if (response.responseStatus === 0) {
                        toast.error(response.error.message);
                    } else {
                        history.push('/thankYou');
                    }
                } else toast.error('Error occured');
                setIsProcessing(false);
            });
        }
    };

    const videoHtmlTag = (date, time, comment) => `--VIDEO APPOINTMENT--</br>
  DATE REQUESTED: <strong> + ${date} + </strong></br>
  PREFERRED TIME: <strong> + ${time} + </strong></br>
  -------------------</br>
  ${comment}`;

    useEffect(() => {
        setValue('agentId', agent.agentId.toString());
        setValue('URL', window.location.href);
        if (selectedDate) { setValue('appointmentDate', selectedDate); }
    }, []);

    useEffect(() => {
        if (agent.agentAppointmentAvailabilty.length > 0 && selectedDate) {
            const data = agent.agentAppointmentAvailabilty.find((x) => x.dayOfWeek === selectedDate.day());
            setAvailableTimes([{
                title: 'Morning',
                isAvailable: data.morning,
            },
            {
                title: 'Afternoon',
                isAvailable: data.afternoon,
            },
            {
                title: 'Evening',
                isAvailable: data.evening,
            }]);
        }
    }, [selectedDate, agent]);

    return (
        <div className="p-2 flex flex-col gap-1 max-w-2xl">

            <div className="text-lg font-semibold">
                Request a Video Appointment with
        {' '}
                {`${agent.firstName} ${agent.lastName}`}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-1">

                <div className="flex flex-col">
                    <input className="hidden" {...register('agentId', { required: true })} />
                    <div>
                        <label>
                            Please provide your preferred video appointment date.
                            Your agent will be in touch with you to confirm your date and time,
                            or schedule an alternative time if necessary.

                        </label>
                    </div>
                    <div>

                        <ReactDatePicker
                            placeholderText="Date of appointment"
                            autoComplete="off"
                            className="border border-gray-400  py-2 px-4 w-full"
                            name="appointmentDate"
                            {...register('appointmentDate', { required: true })}
                            selected={selectedDate ? selectedDate.toDate() : null}
                            onChange={(date) => {
                                if (date) {
                                    setSelectedDate(moment(date));
                                    setValue('appointmentDate', moment(date));
                                    setValue('preferredTime', '');
                                    trigger('appointmentDate');
                                }
                            }}
                            filterDate={(date) => agent.agentAppointmentAvailabilty.some((x) => x.dayOfWeek === date.getDay())}
                        // excludeDates={[new Date(), subDays(new Date(), 1)]}
                        />
                    </div>
                    <div>
                        {errors.appointmentDate && <span className="text-red-500 text-sm">Date of appointment is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <input className="hidden" {...register('agentId', { required: true })} />
                    <div>
                        <label>Preferred Time to meet</label>
                    </div>
                    <div>
                        <select disabled={!selectedDate} {...register('preferredTime', { required: true })} className="border border-gray-400  py-2 px-4 w-full">
                            <option value="">Select preferred time</option>
                            {availableTimes.filter((x) => x.isAvailable).map((x) => <option key={x.title} value={x.title}>{x.title}</option>)}

                        </select>
                    </div>
                    <div>
                        {errors.preferredTime && <span className="text-red-500 text-sm">Preferred Time to meet is required</span>}
                    </div>
                </div>
                <div className="flex flex-row gap-10">
                    <div className="flex flex-col flex-1">
                        <input className="hidden" {...register('agentId', { required: true })} />
                        <div>
                            <label>First Name</label>
                        </div>
                        <div>
                            <input placeholder="First Name" className="border border-gray-400  py-2 px-4 w-full" {...register('firstName', { required: true })} />
                        </div>
                        <div>
                            {errors.firstName && <span className="text-red-500 text-sm">First name is required</span>}
                        </div>
                    </div>

                    <div className="flex flex-col flex-1">
                        <div>
                            <label>Last Name</label>
                        </div>
                        <div>
                            <input placeholder="Last Name" className="border border-gray-400  py-2 px-4 w-full" {...register('lastName', { required: true })} />
                        </div>
                        <div>
                            {errors.lastName && <span className="text-red-500 text-sm">Last name is required</span>}
                        </div>
                    </div>
                </div>

                <div className="flex flex-row gap-10">
                    <div className="flex flex-col flex-1">
                        <div>
                            <label>Email</label>
                        </div>
                        <div>
                            <input placeholder="Email" type="email" className="border border-gray-400  py-2 px-4 w-full" {...register('email', { required: true })} />
                        </div>
                        <div>
                            {errors.email && <span className="text-red-500 text-sm">Email is required</span>}
                        </div>
                    </div>

                    <div className="flex flex-col flex-1">
                        <div>
                            <label>Phone Number</label>
                        </div>
                        <div>
                            <input placeholder="Phone number" className="border border-gray-400  py-2 px-4 w-full" {...register('phone')} />
                        </div>
                        {errors.phone && <span className="text-red-500 text-sm">Phone number is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Your questions / comments to this agent</label>
                    </div>
                    <div>
                        <textarea placeholder="Comment" className="border border-gray-400  py-2 px-4 w-full" {...register('comment')} />
                        {errors.comment && <span className="text-red-500 text-sm">Question/Comment is required</span>}

                    </div>
                </div>
                <div className="flex justify-center">
                    <div style={{ maxWidth: '300px' }}>
                        <ReCAPTCHA
                            sitekey="6Leim3YUAAAAAIKdoLmCjB0HPr9HsqhnCTmBNSuZ"
                            onChange={(value) => setIsCaptchaVerified(!!value)}
                            hl="en"
                        />
                    </div>
                </div>
                <div>
                    <button
                        disabled={isProcessing || !isCaptchaVerified}
                        type="submit"
                        className={[' rounded-full py-2 px-10 text-white text-sm w-full',
                            isProcessing ? 'bg-gray-300' : 'bg-red-600'].join(' ')}
                    >
                        {
                            isProcessing ? 'Requesting video appointment ....'
                                : 'Request Video Appointment'
                        }
                    </button>
                </div>
            </form>

        </div>

    );
}

AppointmentForm.propTypes = {
    agent: PropTypes.any.isRequired,

};
export default AppointmentForm;
