/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-template */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
    useEffect, useState, useRef, createRef,
} from 'react';
import {
    IoClose,
    IoStarOutline,
    IoGlobeOutline,
    IoChevronBack,
    IoCheckmarkCircleSharp,
    IoLocationSharp,
} from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import SlideDown from 'react-slidedown';
import parser from 'html-react-parser';
import Modal from 'react-modal';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ReactImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player';
import Skeleton from 'react-loading-skeleton';
import Tooltip from 'react-simple-tooltip';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Helmet } from 'react-helmet';

import {
    getStateName,
    modalCustomStyles,
    processCertificateImages,
    PROMO_IMAGE_URL,
    socialIcons,
} from '../utils';
import API from '../services/API';
import WhyComponent from '../components/WhyComponent';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { AgentMap } from '../components/AgentMap';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-loading-skeleton/dist/skeleton.css';
import AgentLabel from '../components/AgentLabel';
import AgentClientReview from '../components/AgentClientReview';
import AgentTestimonials from '../components/AgentTestimonials';
import AgentItineraries from '../components/AgentItineraries';
import BreadCrumb from '../components/BreadCrumb';
import ButtonInverted from '../components/elements/ButtonInverted';
import BookedVisitedInfo from '../components/elements/BookedVisitedInfo';

import sanitizeHtml from 'sanitize-html-react';

const ModalTypes = ['destinations', 'interests', 'suppliers', 'album', 'video', 'travel'];

function Agent() {
    const { id } = useParams();

    function useQuery() {
        return new URLSearchParams(useLocation().search.toLowerCase());
    }

    const query = useQuery();

    const [links, setLinks] = useState([{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a travel Agent',
        url: '/find_travel_agent',
    },
    ]);

    const [section, setSection] = useState(query.get('s') || '');
    const [previewMode, setPreviewMode] = useState(query.get('preview') || false);
    const [referrer, setReferrer] = useState(query.get('ref') || '');
   /* const [supplier, setSupplier] = useState(query.get('supplier') || '');*/
    const [destination, setDestination] = useState(query.get('slctdestination')?.split('|')[0].trim() || '');
    const [loading, setLoading] = useState(false);
    const [agent, setAgent] = useState();
    const [modalType, setModalType] = useState('');
    const [certificates, setCertificates] = useState([]);
    const history = useHistory();
    const travelRef = useRef(null);
    const aboutRef = useRef(null);
    const certificateRef = useRef(null);
    const testimonialRef = useRef(null);
    const myPlacesRef = useRef(null);
    const myPlacesContainerRef = useRef(null);
    const reviewsRef = useRef(null);
    const myAlbumRef = useRef(null);
    const selectedPlaceRef = useRef(null);
    const itinerariesRef = useRef(null);
    const [agentTitle, setAgentTitle] = useState('');
    const [agentText, setAgentText] = useState('');
    const [itemToShow, setItemToShow] = useState(5);
    const [showFull, setShowFull] = useState(false);
    const [showFullReviews, setShowFullReviews] = useState(false);
    const [reviewIdToScroll, setReviewIdToScroll] = useState(null);
    const [noAgentReturned, setNoAgentReturned] = useState(false);
    const [albums, setAlbums] = useState([]);
    const [selectedAlbumId, setSelectedAlbumId] = useState();
    const [videos, setVideos] = useState([]);
    const [stories, setStories] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState();
    const [additionalBio, setAdditionalBio] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [refs, setRefs] = useState([]);

    const executeScroll = (ref, block = 'start', offset = 120) => {
        if (ref.current) {
            const element = ref.current; // Get the current top position of the element
            const elementTop = element.getBoundingClientRect().top;
            // Get the current scroll position
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // Calculate the target position with the offset
            const targetPosition = elementTop + scrollTop - offset;
            // Scroll to the target position smoothly
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth',
            });
        }
    };

    const executeScrollPlace = () => {
        setTimeout(() => {
            selectedPlaceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 200);
    };

    const prepareAdditionalBio = (data) => {
        if (data.agentAddlSpecializations.some((x) => x.addlBioId !== 0)) {
            setAdditionalBio(data.agentAddlSpecializations);
        }
    };

    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,
            agentPreview: previewMode,
            agentDestination: destination,
        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    setCertificates(processCertificateImages(result.data));
                    prepareAdditionalBio(result.data);
                    getAgentAlbum();
                    getTravelStories();
                    getItineraries();

                    const drefs = [];
                    result.data.agentReviews.map((x) => {
                        drefs[x.reviewId] = createRef();
                    });

                    setRefs(drefs);
                }
                if (result.data.agentId === 0) {
                    setNoAgentReturned(true);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentAlbum = () => {
        setLoading(true);
        API.agentAlbum({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setAlbums(result.data);
                getAgentVideos();
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getTravelStories = () => {
        setLoading(true);
        API.experimentalPost({
            agentId: id,
            bioId: 0,
            blogId: 0,
            returnAll: 'true',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setStories(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentVideos = () => {
        setLoading(true);
        API.agentVideos({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setVideos(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getItineraries = () => {
        setLoading(true);
        API.agentItineraries({
            agentId: id,
            bioId: 0,
            returnAll: 'true',
            preview: 'false',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setItineraries(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
        window.scrollTo(0, 0);
    }, []);

    // Bug fix for my places read More Review Scrolling issue
    // Opens the accordion first (setShowFullReviews) then scrolls to the correct review id
    useEffect(() => {
        if (showFullReviews && reviewIdToScroll && refs[reviewIdToScroll]) {
            executeScroll(refs[reviewIdToScroll], 'center');
            setReviewIdToScroll(null); // Reset after scrolling
        }
    }, [showFullReviews, reviewIdToScroll, refs]);

    const handleParentButtonClick = (reviewId) => {
        setShowFullReviews(true);
        setReviewIdToScroll(reviewId);
    };
    // End my places reviews fix

    const constructAgentTitle = () => {
        if (agent.agentAddlSpecializations.length === 0) {
            setAgentTitle(agent.overviewTitle.length > 0 ? agent.overviewTitle : agent.websiteDisplayTitle);
            setAgentText(agent.expertiseOverview.length > 0 ? agent.expertiseOverview : agent.bioText);
        }
        if (agent.agentAddlSpecializations.length === 1) {
            setAgentTitle(agent.agentAddlSpecializations[0].displayTitle);
            setAgentText(agent.agentAddlSpecializations[0].bioText);
        } else if (agent.agentAddlSpecializations.length > 1) {
            setAgentTitle(agent.overviewTitle);
            setAgentText(agent.expertiseOverview);
        }
    };

    const wrapperClass = '-mx-2 md:-mx-20 py-8 md:py-14 md:px-20 flex flex-col';
    const h2BaseClass = 'text-center md:text-left tl-cyan uppercase text-2xl md:text-4xl  tracking-wide';

    useEffect(() => {
        if (agent) {
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?slctState=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
            }]);
            constructAgentTitle();
        }
    }, [agent]);

    useEffect(() => {
        if (section !== '' && reviewsRef.current) {
            if (section === 'review') {
                setTimeout(() => {
                    executeScroll(reviewsRef);
                }, 1000);
            }
        }
    }, [section, reviewsRef.current]);

    const closeModal = () => {
        //  closeFormModal();
        setModalType('');
    };

    const loader = () => (
        <div className="max-w-screen-2x px-4 min-h-screen ">
            <div>
                <div className="py-4 w-full inline-block align-top">
                    <Skeleton count={1} height={400} />
                </div>
                <div className="py-4 w-full inline-block align-top">
                    <Skeleton count={1} height={800} />
                </div>
            </div>
        </div>
    );

    const tabBar = () => {
        const item = 'flex-none w-32 md:w-auto';
        const baseClass = 'text-red-600 md:border rounded-3xl border-red-400 hover:bg-red-400 hover:text-white md:p-2 md:mx-2  cursor-pointer hover:scale-105 transition transform  ease-in-out duration-500';

        const displayTabBar = ((additionalBio.length > 0 ? 1 : 0)
            + ((certificates.length > 0 || agent.agentCertifications.length > 0) ? 1 : 0)
            + (agent.agentReviews.length > 0 ? 1 : 0)
            + (agent.agentTestimonials.length > 0 ? 1 : 0)
            + (agent.agentMaps.length > 0 ? 1 : 0)) >= 2;

        return (
            <>
                {displayTabBar && (
                    <>
                        {/*Desktop View*/}
                        <div className="p-5 border shadow-xl z-10 hidden md:flex md:flex-row text-center text-sm bg-white justify-center uppercase full-length">
                            {additionalBio.length > 0 && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(travelRef);
                                        }}
                                    >
                                        My Travel Specialties

                                    </div>
                                </>
                            )}
                            {agent.agentReviews.length > 0 && (
                                <>
                                    {' '}
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(reviewsRef);
                                        }}
                                    >
                                        Reviews
                                    </div>
                                </>
                            )}
                            {(certificates.length > 0 || agent.agentCertifications.length > 0) && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(certificateRef);
                                        }}
                                    >
                                        Certificates &amp; Awards
                                    </div>
                                </>
                            )}
                            {agent.agentMaps.length > 0 && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(myPlacesRef);
                                        }}
                                    >
                                        My Places
                                    </div>
                                </>
                            )}
                            {(albums.length > 0 || videos.length > 0 || stories.length > 0) && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(myAlbumRef);
                                        }}
                                    >
                                        Travel Stories &amp; Media
                                    </div>
                                </>
                            )}
                            {itineraries.length > 0 && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(itinerariesRef);
                                        }}
                                    >
                                        Itineraries
                                    </div>
                                </>
                            )}
                            {agent.agentTestimonials.length > 0 && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(testimonialRef);
                                        }}
                                    >
                                        Client Testimonials
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    };

    const tabBarMobile = () => {
        const item = 'flex-none text-center leading-none';
        const baseClass = '';

        const displayTabBar = ((additionalBio.length > 0 ? 1 : 0)
            + ((certificates.length > 0 || agent.agentCertifications.length > 0) ? 1 : 0)
            + (agent.agentReviews.length > 0 ? 1 : 0)
            + (agent.agentTestimonials.length > 0 ? 1 : 0)
            + (agent.agentMaps.length > 0 ? 1 : 0)) >= 2;

        return (
            <>
                {displayTabBar && (
                    <>
                        {/*Mobile View*/}
                        <div
                            className="p-5 border-bottom shadow-xl z-10 flex md:hidden overflow-x-scroll no-scrollbar text-red-600 cursor-pointer uppercase underline text-sm bg-white -mt-4 -mx-4"
                            style={{ textDecorationColor: '#DC2626' }}
                        >
                            <div className="flex space-x-4">
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(aboutRef);
                                    }}
                                >
                                    About
                                </div>
                                {additionalBio.length > 0 && (
                                    <>
                                        <div
                                            className={[item, baseClass].join(' ')}
                                            onClick={() => {
                                                executeScroll(travelRef);
                                            }}
                                        >
                                            My Travel Specialties

                                        </div>
                                    </>
                                )}
                                {agent.agentReviews.length > 0 && (
                                    <>
                                        {' '}
                                        <div
                                            className={[item, baseClass].join(' ')}
                                            onClick={() => {
                                                executeScroll(reviewsRef);
                                            }}
                                        >
                                            Reviews
                                        </div>
                                    </>
                                )}
                                {(certificates.length > 0 || agent.agentCertifications.length > 0) && (
                                    <>
                                        <div
                                            className={[item, baseClass].join(' ')}
                                            onClick={() => {
                                                executeScroll(certificateRef);
                                            }}
                                        >
                                            Certificates &amp; Awards
                                        </div>
                                    </>
                                )}
                                {agent.agentMaps.length > 0 && (
                                    <>
                                        <div
                                            className={[item, baseClass].join(' ')}
                                            onClick={() => {
                                                executeScroll(myPlacesRef);
                                            }}
                                        >
                                            My Places
                                        </div>
                                    </>
                                )}
                                {(albums.length > 0 || videos.length > 0 || stories.length > 0) && (
                                    <>
                                        <div
                                            className={[item, baseClass].join(' ')}
                                            onClick={() => {
                                                executeScroll(myAlbumRef);
                                            }}
                                        >
                                            Travel Stories &amp; Media
                                        </div>
                                    </>
                                )}
                                {itineraries.length > 0 && (
                                    <>
                                        <div
                                            className={[item, baseClass].join(' ')}
                                            onClick={() => {
                                                executeScroll(itinerariesRef);
                                            }}
                                        >
                                            Itineraries
                                        </div>
                                    </>
                                )}
                                {agent.agentTestimonials.length > 0 && (
                                    <>
                                        <div
                                            className={[item, baseClass].join(' ')}
                                            onClick={() => {
                                                executeScroll(testimonialRef);
                                            }}
                                        >
                                            Client Testimonials
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const agentAboutMe = () => (
        <div className="" ref={aboutRef}>
            <h2 className={`${h2BaseClass} md:hidden`}>About Me</h2>
        </div>
    );

    const agentBio = () => (
        <div className="flex flex-col gap-4">
            <BreadCrumb links={links} />
            {referrer === 'search' ? (
                    <div className="text-gray-500 text-sm font-semibold flex flex-row items-center cursor-pointer gap-1" onClick={() => { history.goBack(); }}>
                        <IoChevronBack className=" text-right" />
                        <div>
                            {' '}
                            Return to Search Results
                        </div>
                    </div>
            ) : ''}
            {/*agent header details*/}
            <AgentLabel agent={agent} executeScroll={executeScroll} sectionRef={reviewsRef} />
            {/*agent header details*/}
            {tabBarMobile()}
            {/*mobile booked visited*/}
            <BookedVisitedInfo
                className="block mt-8 md:my-0 md:hidden"
                agent={agent}
            />
            <div className="md:hidden">
                {agentAreaOfExpertise()}
            </div>

            {agentAboutMe()}
            <div className="flex flex-col gap-2 md:py-4">
                <h3 className="uppercase text-l md:text-xl mb-2 font-semibold tracking-wider tl-black-900">{agentTitle}</h3>
                <div className="text-lg pb-6 agentText">
                    {(agent.addlInfoHeader === '') && (agent.addlInfoText === '') && (agent.agentAddlSpecializations.length <= 1) ? (
                        <div className="float-left mr-4 pt-6">
                            <img src={agent.travelPhotoFileName} className="w-96 " alt={agent.travelPhotoCaption} loading="lazy" />
                            <div className="text-center text-sm text-gray-600 italic">
                                {agent.travelPhotoCaption}
                            </div>
                        </div>
                    ) : ''}
                    {parser(sanitizeHtml(agentText))}
                </div>
                {/*mobile languages/socials*/}
                <div className="block pb-4 md:hidden">
                    {agent.agentLanguages.length > 0 && (
                        <div className="flex flex-col items-center pb-4">
                            <div className="flex justify-center text-l">
                                <IoGlobeOutline />
                            </div>
                            <div className="text-xs uppercase font-bold text-center">
                                Additional Languages
                            </div>
                            <div className="flex justify-center flex-wrap items-center gap-1">
                                {agent.agentLanguages.map((x, index) => (
                                    <div
                                        className="tl-cyan cursor-pointer hover:text-black mr-1 text-sm inline-block"
                                        key={index}
                                        onClick={() => history.push(`/agents?slctLanguage=${encodeURIComponent(x.language)}&agentId=${agent.agentId}`)}
                                    >
                                        {`${x.language}${index + 1 < agent.agentLanguages.length ? ',' : ''}`}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {/*socials*/}
                    <div className="bg-light-blue text-4xl flex flex-row gap-4 justify-center">
                        {agent.agentConnections.map((x) => (
                            socialIcons[x.contactMethod] && (
                                <div key={x.contactMethod}>
                                    <a
                                        href={x.contactValue}
                                        target="_blank"
                                        aria-label={socialIcons[x.contactMethod].ariaLabel}
                                        rel="noreferrer"
                                    >
                                        <i className={`${socialIcons[x.contactMethod].icon} py-2 text-gray-900`} />
                                    </a>
                                </div>
                            )
                        ))}
                    </div>
                </div>
                {(agent.addlInfoHeader !== '') && (agent.addlInfoText !== '') && (agent.agentAddlSpecializations.length <= 1) ? (
                    <>
                        <h3 className="uppercase text-l md:text-xl mb-2 font-semibold tracking-wider tl-black-900">{agent.addlInfoHeader}</h3>
                        <div className="text-lg pt-2 pb-6">
                            <div className="float-left mr-4">
                                <img src={agent.travelPhotoFileName} className="w-96 " alt={agent.travelPhotoCaption} loading="lazy" />
                                <div className="text-center text-sm text-gray-600 italic">
                                    {agent.travelPhotoCaption}
                                </div>
                            </div>
                            {parser(sanitizeHtml(agent.addlInfoText))}
                        </div>
                    </>
                ) : ''}
            </div>
        </div>
    );

    const DisplayAgentAttributes = ({ data, getDisplayName, getLink }) => {
        const [showAll, setShowAll] = useState(false);
        // Slice data based on `showAll` state
        const visibleData = showAll ? data : data.slice(0, 6);

        return (
            <>
                {/* Display the data with links */}
                {visibleData.map((item, index) => {
                    const displayName = getDisplayName(item);
                    return (
                        displayName && (
                            <span
                                key={index}
                                onClick={(e) => {
                                    getLink && getLink(item, history);
                                }}
                                className="hover:text-red-500 cursor-pointer"
                            >
                                {displayName}
                                {index < visibleData.length - 1 && ', '}
                            </span>
                        )
                    );
                })}

                {/* See All / See Less link */}
                {data.length > 6 && (
                    <button
                        type="button"
                        onClick={() => setShowAll(!showAll)}
                        className="text-red-700 cursor-pointer hover:text-red-900 pl-1"
                    >
                        {showAll ? '< See Less ' : 'See All >'}
                    </button>
                )}
            </>
        );
    };

    const agentAreaOfExpertise = () => (
        <div className={wrapperClass}>
            <div id="Expertise" className="flex flex-col gap-2 items-center justify-center md:bg-light-blue">
                <h3 className="uppercase text-xl font-semibold tracking-wider tl-black-900">Areas of expertise</h3>
                <div className="flex flex-col md:flex-row justify-center gap-4 p-2 md:p-0">
                    {agent.agentDestinations.length > 0 && (
                        <div className="flex-1">
                            <span className="font-bold">Destinations: </span>
                            <DisplayAgentAttributes
                                data={agent.agentDestinations}
                                getDisplayName={(myDestination) => {
                                    switch (myDestination.locationType) {
                                        case 'Reg':
                                            return myDestination.region;
                                        case 'Are':
                                            return myDestination.area;
                                        case 'Cit':
                                            return myDestination.locale;
                                        default:
                                            return '';
                                    }
                                }}
                                getLink={(myDestination) => {
                                    history.push(
                                        `/agents?slctDestination=${encodeURIComponent(
                                            myDestination.area || myDestination.locale || myDestination.region,
                                        )}&destinationId=${myDestination.locationId}&agentId=${agent.agentId}`,
                                    );
                                }}
                            />
                        </div>
                    )}
                    {agent.agentInterests.length > 0 && (
                        <div className="flex-1">
                            <span className="font-bold">Interests: </span>
                            <DisplayAgentAttributes
                                data={agent.agentInterests}
                                getDisplayName={(myInterest) => myInterest.interestName}
                                getLink={(myInterest) => {
                                    history.push(
                                        `/agents?slctInterest=${encodeURIComponent(myInterest.interestName)}&agentId=${agent.agentId}`,
                                    );
                                }}
                            />
                        </div>
                    )}
                    {agent.agentSuppliers.length > 0 && (
                        <div className="flex-1">
                            <span className="font-bold">Suppliers: </span>
                            <DisplayAgentAttributes
                                data={agent.agentSuppliers}
                                getDisplayName={(mySupplier) => mySupplier.supplierName}
                                getLink={(mySupplier) => {
                                    history.push(
                                        `/agents?slctSupplier=${encodeURIComponent(mySupplier.supplierName)}&agentId=${agent.agentId}`,
                                    );
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const [expandedItems, setExpandedItems] = useState({}); // Track expanded state of items

    const toggleExpand = (index) => {
        setExpandedItems((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle the specific item
        }));
    };

    const agentTravelSpecialist = () => (
        <div className={[wrapperClass, 'bg-white'].join(' ')} ref={travelRef}>
            <h2 className={h2BaseClass}>My Travel Specialties</h2>
            <div className="grid grid-cols-1  md:grid-cols-3 gap-4 md:gap-8 my-4 px-2 md:px-0">
                {additionalBio.map((x, index) => (
                    <div
                        className="flex flex-col gap-4"
                        key={index}
                    >
                        <div className="text-l font-semibold uppercase flex items-center justify-between">
                            {/* Arrow visible only on mobile */}
                            <span
                                className="block md:hidden"
                                onClick={() => toggleExpand(index)}
                            >
                                {x.displayTitle}
                            </span>
                            <span
                                className={`text-xl transform transition-transform tl-red duration-300 md:hidden cursor-pointer ${expandedItems[index] ? 'rotate-90' : 'rotate-0'}`}
                                onClick={() => toggleExpand(index)}
                            >
                                &#9654;
                            </span>
                        </div>
                        {/* Expandable content */}
                        <div
                            className={`overflow-hidden cursor-pointer transition-all duration-500 ease-in-out ${expandedItems[index] ? 'max-h-96' : 'max-h-0'} md:max-h-full`}
                            onClick={() => history.push(`/agent/${agent.agentId}/bio/${x.addlBioId}`)}
                        >
                            <div className="">
                                <img src={x.travelImage} className="w-96" alt={x.displayTitle} loading="lazy" />
                                {' '}
                            </div>
                            <div className="hidden md:block text-l font-semibold uppercase my-2">
                                {x.displayTitle}
                            </div>
                            <div className="text-base">
                                {parser(
                                    `${sanitizeHtml(
                                        x.bioText.length > 300 ? x.bioText.slice(0, 300) : x.bioText,
                                        { allowedTags: [], allowedAttributes: {} },
                                    )}...<span class="tl-red cursor-pointer hover:text-black">Read More &#9654;`,
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    const agentTravelStories = () => (
        <div className={[wrapperClass, 'bg-white py-0 md:py-0'].join(' ')} ref={myAlbumRef}>
            {(albums.length > 0 || videos.length > 0) && (
                <div className="flex flex-col gap-2 py-8 md:py-14 md:px-10 -mx-4 md:-mx-20 md:-mt-14 justify-center bg-light-blue">
                    <h2 className={`${h2BaseClass} px-4 md:px-10`}>My Photo Albums and Videos</h2>
                    {/*Destktop Display*/}
                    <div className="hidden md:grid md:grid-cols-3 md:gap-4 md:p-10">
                        {albums.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4 pb-4 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-1000"
                                key={x.albumId}
                                onClick={() => {
                                    setSelectedAlbumId(x.albumId);
                                    setModalType('album');
                                }}
                            >
                                <div className="flex  w-full  overflow-hidden">
                                    <img
                                        src={x.albumImages[0].image}
                                        className="w-full h-56 mx-auto object-cover"
                                        alt=""
                                        loading="lazy"
                                    />
                                    {' '}
                                </div>

                                <div className="flex flex-wrap md:flex-nowrap space-x-2">
                                    <div className="flex w-full self-center text-center tl-cyan text-lg font-medium leading-tight">{x.albumTitle}</div>
                                    <div className="w-full text-center">
                                        <div className="tl-red cursor-pointer font-semibold uppercase hover:text-black">
                                            View Album
                                            <i className="fa fa-caret-right" aria-hidden="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                        {videos.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-1000"
                                key={x.videoId}
                            >
                                <div className="flex md:h-56 overflow-hidden mx-auto bg-black">
                                    <ReactPlayer
                                        controls
                                        url={x.videoURL}
                                        width="100%"
                                        height="100%"
                                    />
                                </div>

                                <div className="flex">
                                    <div className="item w-full text-center"><span className="tl-cyan text-lg font-medium">{x.videoTitle}</span></div>
                                </div>
                            </div>

                        ))}
                    </div>
                    {/*Mobile Display*/}
                    <div className="md:hidden w-full text-center">
                        <Slide
                            duration={5000}
                            arrows={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            transitionDuration={500}
                            pauseOnHover={true}
                            indicators={true}
                            canSwipe={true}
                            autoplay={false}
                        >
                            {albums.map((x) => (
                                <div
                                    className="flex flex-col gap-4 px-4 pb-4"
                                    key={x.albumId}
                                    onClick={() => {
                                        setSelectedAlbumId(x.albumId);
                                        setModalType('album');
                                    }}
                                >
                                    <div className="flex  w-full md:w-96 overflow-hidden">
                                        <img
                                            src={x.albumImages[0].image}
                                            className="w-full md:w-96 h-56 mx-auto object-cover"
                                            alt=""
                                            loading="lazy"
                                        />
                                        {' '}
                                    </div>

                                    <div className="flex flex-wrap md:flex-nowrap space-x-2">
                                        <div className="w-full md:w-2/3 text-center"><span className="tl-cyan text-lg font-medium">{x.albumTitle}</span></div>
                                        <div className="w-full md:w-1/3 text-center">
                                            <div className="tl-red cursor-pointer font-semibold uppercase hover:text-black">
                                                View Album
                                            <i className="fa fa-caret-right" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Slide>
                    </div>
                    <div className="md:hidden w-full text-center">
                        <Slide
                            duration={7500}
                            arrows={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            transitionDuration={500}
                            pauseOnHover={true}
                            indicators={true}
                            canSwipe={true}
                            autoplay={false}
                        >
                            {videos.map((x) => (
                                <div
                                    className="flex flex-col gap-4 px-4 cursor-pointer"
                                    key={x.videoId}
                                >
                                    <div className="flex  md:w-96 h-44 md:h-56 overflow-hidden mx-auto bg-black">
                                        <ReactPlayer
                                            controls
                                            url={x.videoURL}
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>

                                    <div className="flex">
                                        <div className="item w-full text-center"><span className="tl-cyan text-lg font-medium">{x.videoTitle}</span></div>
                                    </div>
                                </div>

                            ))}
                        </Slide>
                    </div>
                    {/*END MOBILE DISPLAY*/}
                </div>
            )}
            {stories.length > 0 && (
                <div className="my-10">
                    <h2 className={h2BaseClass}>My Travel Stories</h2>
                    <div className="md:pt-10">
                        <SlideDown className="my-dropdown-slidedown">
                            {stories.slice(0, showFull ? stories.length : itemToShow).map((x) => (
                                <div
                                    className="flex flex-wrap md:flex-nowrap space-x-2 p-4  cursor-pointer"
                                    key={x.postTitle}
                                    onClick={() => history.push(`/agent/${agent.agentId}/story/${x.postId}`)}
                                >
                                    <div className="w-full md:w-1/4  mb-4 md:m-0">
                                        <img src={x.travelImage} className="w-96" alt={x.travelImageCaption} loading="lazy" />
                                        {' '}
                                    </div>
                                    <div className="w-full md:w-3/4 md:px-10 self-center">
                                        <div className="text-l font-semibold uppercase">{x.postTitle}</div>
                                        <div className="text-base">
                                            {x.postText.length > 350 ? parser(`${sanitizeHtml(x.postText.slice(0, 350), { allowedTags: [], allowedAttributes: {} })}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span><p>`)
                                                : parser(`${sanitizeHtml(x.postText, { allowedTags: [], allowedAttributes: {} })}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span><p>`)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SlideDown>
                        {stories.length > itemToShow && (
                            <div className="flex justify-center space-x-2">
                                {!showFull ? (
                                    <ButtonInverted
                                        title="View all Travel Stories"
                                        onClick={() => setShowFull(!showFull)}
                                        width="md:w-1/4 uppercase"
                                    />
                                ) : (
                                        <ButtonInverted
                                            title="View less Travel Stories"
                                            onClick={() => setShowFull(!showFull)}
                                            width="md:w-1/4 uppercase"
                                        />
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );

    const agentMaps = () => (
        <div
            id="MyPlaces"
            className={[wrapperClass, 'bg-gray-900 pt-8 md:pt-14 pb-0 mb-12 -mx-4 -mt-6 md:mb-0 text-gray-500  items-center'].join(' ')}
            ref={myPlacesRef}
        >
            <h2
                className={h2BaseClass}
                style={{ color: '#FFFFFF' }}
            >
                My Places
            </h2>
            <div className="text-lg leading-7 text-white text-center mt-4 px-8">When it comes to travel planning, real-world experience makes a huge difference. Check out the map below to see all the places I have personally visited or booked for my clients. I know these destinations well and can give you reliable guidance.</div>
            <div className="flex flex-col md:flex-row gap-0 mt-10 w-full " ref={myPlacesContainerRef}>
                <div className="md:w-2/3 md:inline-block">
                    <AgentMap
                        selectedLocation={selectedLocation}
                        callback={(val) => {
                            setSelectedLocation(val);
                        }}
                        locations={agent.agentMaps}
                        containerElement={<div className="w-full h-96 md:h-map" />}
                        mapElement={<div style={{ height: '100%' }} />}
                    />
                </div>
                <div className="md:w-1/3 inline-block bg-white overflow-y-auto h-96 md:h-map z-10 sidebar-shadow" tabIndex="0">

                    {agent.agentMaps.map((x) => (
                        <div
                            key={x.mapId}
                            ref={selectedLocation && x.mapId === selectedLocation.mapId
                                ? selectedPlaceRef : null}
                            className={['p-2 border-b border-gray-300',

                                selectedLocation && selectedLocation.mapId === x.mapId ? 'bg-gray-300' : '',
                            ].join(' ')}

                        >
                            <div className="flex justify-center space-x-2">
                                <div className="item w-full">
                                    <div
                                        className="text-sm font-bold uppercase cursor-pointer pb-2"
                                        onClick={() => setSelectedLocation(x)}
                                    >
                                        {`${x.locale}${x.locale ? ', ' : ''}${x.area}${x.area ? ', ' : ''}${x.region}`}

                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center space-x-2">
                                <div className="item w-1/2">
                                    <div className="pb-2">
                                        {x.bookedIt
                                            && (
                                                <div className="text-sm font-light">
                                                    <i className="fas fa-plane text-blue-700" />
                                                    {' '}
                                                    {x.bookingCount > 1 ? `Booked It ${x.bookingCount} Times` : 'Booked It'}
                                                </div>
                                            )}
                                        {x.beenThere
                                            && (
                                                <div className="text-sm font-light">
                                                    <i className="fas fa-suitcase text-green-700" />
                                                    {' '}
                                                    {x.visitCount > 1 ? `Been There ${x.visitCount} Times` : 'Been There'}
                                                </div>
                                            )}
                                    </div>
                                    <StarRatings
                                        rating={x.reviewRating}
                                        starRatedColor="#0A7790"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="2px"
                                    />
                                    <div
                                        className="text-sm text-gray-500 font-light"
                                        onClick={() => handleParentButtonClick(x.reviewId)}
                                    >
                                        {x.review.length > 50 ? parser(`${sanitizeHtml(x.review.slice(0, 50))}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`) : x.review}

                                    </div>
                                </div>
                                <div className="item w-1/2 self-center">
                                    {x.locationPhoto
                                    && (
                                        <img
                                            src={x.locationPhoto}
                                            className="object-contain w-full"
                                            alt=""
                                            loading="lazy"
                                        />
                                     )}
                                </div>
                            </div>
                            <div className="flex justify-center space-x-2">
                                <div className="item w-full">
                                    {x.locationTip && (
                                        <>
                                            <div className="text-sm text-gray-500 font-semibold mt-4">Must Do:</div>
                                            <div className="text-sm text-gray-500 font-light">{x.locationTip}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );

    const agentCertificate = () => {
        const numberOfCertificates = agent.agentCertifications.filter((x) => !['TTAUS', 'TTACA', 'CAMP'].includes(x.certification)).length + certificates.length;

        const itemWrapper = 'flex-shrink-0 tl-cyan';

        const RenderImage = ({ image, agentId, description }) => {
            const isLinkedImage = image === '/images/HMDW-Registry-Icon.webp';

            return isLinkedImage ? (
                <a
                    target="_blank"
                    className="cursor-pointer"
                    href={`https://travelleaders.honeymoonwishes.com/?agentId=${agentId}`}
                    rel="noreferrer"
                >
                    <img
                        src={image}
                        className="w-20 h-20 md:w-48 md:h-auto"
                        alt={description}
                        loading="lazy"
                    />
                </a>
            ) : (
                    <img
                        src={image}
                        className="w-20 h-20 md:w-48 md:h-auto"
                        alt={description}
                        loading="lazy"
                    />
                );
        };

        const CertificateWithTooltip = ({ description, image, agentId }) => (
            <>
                <div className={`${itemWrapper} hidden md:flex justify-center`}>
                    <Tooltip
                        placement="bottom"
                        padding={5}
                        content={description}
                        zIndex={999}
                    >
                        <RenderImage
                            image={image}
                            agentId={agentId}
                            description={description}
                        />
                    </Tooltip>
                </div>
                <div className={`${itemWrapper} flex justify-center md:hidden`}>
                        <RenderImage
                            image={image}
                            agentId={agentId}
                            description={description}
                        />
                </div>
            </>
        );

        const CertificationBadge = ({ certification, imageFile }) => (
            <div className={itemWrapper}>
                {imageFile ? (
                    <img
                        src={`${PROMO_IMAGE_URL()}/supplierCerts/${imageFile}`}
                        className="w-20 h-20 md:w-48 md:h-auto mx-auto"
                        alt={certification}
                        loading="lazy"
                    />
                ) : (
                        <IoStarOutline className="w-20 h-20 md:w-36 md:h-36 mx-auto" />
                    )}
                <div className="w-full mx-auto mx-auto text-xs md:text-sm font-medium text-center">{certification}</div>
            </div>
        );
        return (
            <div
                id="Awards"
                className="mb-6 py-6 md:py-16 md:px-20 -mx-2 md:-mx-20 bg-light-blue"
                style={{
                    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.5)',
                }}
                ref={certificateRef}
            >
                <h2
                    className={h2BaseClass}
                    style={{ textAlign: 'center' }}
                >
                    Certifications &amp; Awards
                </h2>
                <div className={[
                    'flex flex-nowrap md:justify-around gap-10 overflow-x-auto md:overflow-visible md:grid text-xs font-semibold mx-auto mt-4 md:mt-16',
                    numberOfCertificates === 1 ? 'md:grid-cols-1'
                        : numberOfCertificates < 6 ? `md:grid-cols-${numberOfCertificates}`
                        : 'md:grid-cols-6',
                ].join(' ')}
                >
                    {certificates.map((x, index) => (
                        <CertificateWithTooltip
                            key={index}
                            description={x.description}
                            image={x.image}
                            agentId={agent.agentId}
                        />
                    ))}

                    {agent.agentCertifications
                        .filter((x) => !['TTAUS', 'TTACA', 'CAMP'].includes(x.certification))
                        .map((x) => (
                            <CertificationBadge
                                key={`${x.certification}-${x.certificationRank}`}
                                certification={x.certification}
                                imageFile={x.imageFile}
                            />
                        ))}
                </div>

            </div>
        );
    };

    const appendSupplier = (data, suplr) => {
        if (suplr === '') {
            return data;
        }
        const appendedSupplier = data || [];
        if (data.length > 0) {
            if (!data.some((x) => x.supplierName === suplr)) {
                appendedSupplier.push({
                    supplierName: suplr,
                });
            }
        } else {
            appendedSupplier.push({
                supplierName: suplr,
            });
        }
        return appendedSupplier;
    };

    useEffect(() => {
        if (selectedLocation) { executeScrollPlace(); }
    }, [selectedLocation]);

    return (
        <div className="max-w-screen-2xl mx-auto">
            <ToastContainer />
            {agent && (
                <div className="h-full  flex flex-col pt-24 md:pt-12 px-4 md:px-10 mx-auto">
                        <div className="md:mx-10">
                            {agentBio()}
                        </div>
                        {tabBar()}
                        <div className="md:mx-10">
                        {/*Desktop booked visited*/}
                            <BookedVisitedInfo
                                className="hidden md:flex md:flex-row md:mt-16 md:block"
                                agent={agent}
                            />
                            <div className="hidden md:block">
                                {agentAreaOfExpertise()}
                            </div>
                            {additionalBio.length > 0 && agentTravelSpecialist()}
                            {agent.agentReviews.length > 0
                                && (
                                    <div ref={reviewsRef}>
                                        <AgentClientReview
                                            agent={agent}
                                            refs={refs}
                                            setLoading={setLoading}
                                            showFull={showFullReviews}
                                            setShowFull={setShowFullReviews}
                                            callback={() => getAgentBio()}
                                        />
                                    </div>
                                )}
                            {(certificates.length > 0 || agent.agentCertifications.length > 0) && agentCertificate()}
                            {agent.agentMaps.length > 0 && agentMaps()}
                            {(albums.length > 0 || videos.length > 0 || stories.length > 0) && agentTravelStories()}
                            {itineraries.length > 0
                                && (
                                    <div ref={itinerariesRef}>
                                        <AgentItineraries agent={agent} itineraries={itineraries} />
                                    </div>
                                )}

                            {agent.agentTestimonials.length > 0
                                && (
                                    <div ref={testimonialRef}>
                                        <AgentTestimonials agent={agent} />
                                    </div>
                                    )}
                        </div>
                        <div className={[wrapperClass, '-mb-10 md:my-0'].join(' ')}>
                            <WhyComponent displayStats="hidden" />
                        </div>
                        <Modal
                            isOpen={ModalTypes.some((x) => x === modalType)}
                            onRequestClose={() => closeModal()}
                            style={modalCustomStyles}
                            contentLabel="Modal"
                            ariaHideApp={false}
                        >
                            <div className="max-w-6xl">
                                <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                                    <IoClose className="text-red-500 text-right" />
                                </div>
                                <div className="px-4">
                                    {modalType === 'album'
                                        && (
                                            <div>
                                                <div className="text-red-700 text-xl font-medium text-center mb-6">
                                                    {albums.find((x) => x.albumId === selectedAlbumId).albumTitle}
                                                </div>
                                                <ReactImageGallery
                                                    items={albums.find((x) => x.albumId === selectedAlbumId)
                                                        .albumImages.map((y) => ({
                                                            original: y.image,
                                                            thumbnail: y.imageThumb,
                                                            thumbnailAlt: y.imageCaption,
                                                            originalTitle: y.imageCaption,
                                                            originalAlt: y.imageCaption,
                                                            originalWidth: 100,
                                                            description: y.imageCaption,
                                                        }))}
                                                    additionalClass="max-w-xs md:max-w-3xl"
                                                    lazyLoad={true}
                                                    showNav={true}
                                                    showPlayButton={false}
                                                    showBullets={false}
                                                />
                                            </div>
                                        )}

                                    {modalType === 'travel' && (
                                        <div className="h-56 flex justify-center items-center">
                                            REDIRECT TO  ADDITIONAL BIO
                                        </div>
                                    )}

                                </div>
                            </div>
                        </Modal>
                </div>
            )}
            {noAgentReturned === true ? (
                <>
                    <Helmet>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                    <div className="mx-4 md:mx-20 py-20 text-center h-screen">
                        <h2 className={`${h2BaseClass} md:text-center`}>Sorry, this agent unavailable</h2>
                        <div>
                            <h3 className="uppercase text-xl my-2 font-semibold tracking-wide">
                                It looks like the agent you have selected is no longer a part of our network.
                                <br />
                                <a href="/find_travel_agent" className="tl-red hover:text-black ">Don’t worry, we have plenty of other agents to choose from</a>
                            </h3>
                        </div>
                    </div>
                </>
            ) : ''}
            {loading && <Loading /> && loader()}
        </div>
    );
}

export default Agent;
