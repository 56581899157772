/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import API from '../services/API';
import { getMoreReferralData, removeUTMCookies } from '../utils';

function EmailForm({ agent }) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const history = useHistory();

    const {
        register, handleSubmit, watch, formState: { errors },
        setValue,
    } = useForm();

    const onSubmit = (data) => {
        if (isCaptchaVerified) {
            setIsProcessing(true);
            API.sendEmailToAgent(data).then((response) => {
                API.sendEmailToAgentAudit(data).then((responseAudit) => {
                    removeUTMCookies();
                    sessionStorage.setItem('user', data.email);
                    history.push({
                        pathname: '/thankYou/',
                        search: `?check=${response.data.toLowerCase()}`,
                    });
                    setIsProcessing(false);
                });
            });
        }
    };

    let referralUrl = window.location.href;
    if (!referralUrl.toLowerCase().includes('utm_')) {
        referralUrl = window.location.href + getMoreReferralData();
    }

    useEffect(() => {
        setValue('agentId', agent.agentId.toString());
        setValue('URL', referralUrl);
    }, []);
    return (
        <div className="p-2 flex flex-col gap-1 max-w-2xl">

            <div className="text-lg font-semibold">
                Sending a message to
        {' '}
                {`${agent.firstName} ${agent.lastName}`}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-1">
                <div className="flex flex-col">
                    <input className="hidden" {...register('agentId', { required: true })} />
                    <div>
                        <label>First Name <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="First Name" className="border border-gray-400  py-2 px-4 w-full" {...register('firstName', { required: true })} />
                    </div>
                    <div>
                        {errors.firstName && <span className="text-red-500 text-sm">First name is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Last Name <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="Last Name" className="border border-gray-400  py-2 px-4 w-full" {...register('lastName', { required: true })} />
                    </div>
                    <div>
                        {errors.lastName && <span className="text-red-500 text-sm">Last name is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Email <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="Email" type="email" className="border border-gray-400  py-2 px-4 w-full" {...register('email', { required: true })} />
                    </div>
                    <div>
                        {errors.email && <span className="text-red-500 text-sm">Email is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Phone Number</label>
                    </div>
                    <div>
                        <input placeholder="Phone number" className="border border-gray-400  py-2 px-4 w-full" {...register('phone')} />
                    </div>
                    {errors.phone && <span className="text-red-500 text-sm">Phone number is required</span>}
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Your questions / comments to this agent <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <textarea placeholder="Comment" className="border border-gray-400  py-2 px-4 w-full" {...register('comment')} />
                        {errors.comment && <span className="text-red-500 text-sm">Question/Comment is required</span>}

                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label className="block leading-none">
                            <input type="checkbox" className="mr-1" {...register('newsLetter')} />
                            <span className="text-xs">
                                I would like to sign-up to receive exclusive travel specials, exciting destination information, &amp; latest insights from our Travel Leaders brands. Unsubscribe any time. Find out about your rights &amp; how your information  is used in our&nbsp;
                                <a className="tl-red hover:text-black" href="https://internova.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>.
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div style={{ maxWidth: '300px' }}>
                        <ReCAPTCHA
                            sitekey="6Leim3YUAAAAAIKdoLmCjB0HPr9HsqhnCTmBNSuZ"
                            onChange={(value) => setIsCaptchaVerified(!!value)}
                            hl="en"
                        />
                    </div>
                </div>
                <div>
                    <button
                        disabled={isProcessing || !isCaptchaVerified}
                        type="submit"
                        className={[' rounded-full py-2 px-10 text-white text-sm w-full',
                            isProcessing ? 'bg-gray-300' : 'bg-red-600'].join(' ')}
                    >
                        {
                            isProcessing ? `Sending email to ${agent.firstName} ${agent.lastName} ....`
                                : `Email ${agent.firstName} ${agent.lastName}`
                        }
                    </button>
                </div>
            </form>

        </div>

    );
}

EmailForm.propTypes = {
    agent: PropTypes.any.isRequired,

};
export default EmailForm;
